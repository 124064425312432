import { type AtomEffect } from 'recoil'

import { LocalStorageEnum } from '@/shared/enums'

export const localStorageEffect =
  <T>(key: LocalStorageEnum): AtomEffect<T> =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key)
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue) as T)
    }

    onSet((newValue, _, isReset) => {
      isReset || newValue === null
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, JSON.stringify(newValue))
    })
  }
